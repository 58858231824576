import React from "react";
import "./App.css";

import { useAuthenticator, withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { AppLayout, Structure } from "./components/AdminLayout";
import {
  BookOutlined,
  DashboardOutlined,
  FileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Interventions } from "./pages/Interventions";
import { InterventionCreate } from "./pages/InterventionCreate";
import { InterventionEdit } from "./pages/InterventionEdit";
import { Dashboard } from "./pages/Dashboard";
import { InterventionDetail } from "./pages/InterventionDetail";
import { InterventionMessageEdit } from "./pages/InterventionMessageEdit";
import { InterventionMessageCreate } from "./pages/InterventionMessageCreate";
import { InterventionMessageDetail } from "./pages/InterventionMessageDetail";
import { IntegrationEdit } from "./pages/IntergrationEdit";
import { IntegrationDetail } from "./pages/IntergrationDetail";
import { IntegrationCreate } from "./pages/IntergrationCreate";
import { Integrations } from "./pages/Intergrations";
import { Runs } from "./pages/RunList";
import { RunCreate } from "./pages/RunCreate";
import { RunDetail } from "./pages/RunDetail";
import { RunEdit } from "./pages/RunEdit";
import { Menu } from "antd";
import { FileList } from "./pages/FileList";
import { FileUpload } from "./pages/FileUpload";
import { SyncUI } from "react-sync-ui";

const structure: Structure[] = [
  {
    key: "/",
    label: "Dashboard",
    icon: <DashboardOutlined />,
    content: <Dashboard />,
  },
  {
    label: "Interventions",
    key: "/interventions",
    icon: <BookOutlined />,
    content: <Interventions />,
    children: [
      {
        key: "/new",
        label: "New intervention",
        hidden: true,
        content: <InterventionCreate />,
      },
      {
        key: "/:interventionId/edit",
        label: "Edit intervention",
        hidden: true,
        content: ({ interventionId }) => (
          <InterventionEdit id={interventionId!} />
        ),
      },
      {
        key: "/:interventionId",
        label: "Intervention",
        hidden: true,
        content: ({ interventionId }) => (
          <InterventionDetail id={interventionId!} />
        ),
        children: [
          {
            key: "/new",
            label: "New Intervention Message",
            hidden: true,
            content: ({ interventionId }) => (
              <InterventionMessageCreate interventionId={interventionId!} />
            ),
          },
          {
            key: "/:id",
            label: "Intervention Message",
            hidden: true,
            content: ({ interventionId, id }) => (
              <InterventionMessageDetail
                interventionId={interventionId!}
                id={id!}
              />
            ),
          },
          {
            key: "/:id/edit",
            label: "Edit Intervention Message",
            hidden: true,
            content: ({ interventionId, id }) => (
              <InterventionMessageEdit
                interventionId={interventionId!}
                id={id!}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    label: "Runs",
    key: "/runs",
    icon: <BookOutlined />,
    content: <Runs />,
    children: [
      {
        key: "/new",
        label: "New Run",
        hidden: true,
        content: <RunCreate />,
      },
      {
        key: "/:id/edit",
        label: "Edit Run",
        hidden: true,
        content: ({ id }) => <RunEdit id={id!} />,
      },
      {
        key: "/:id",
        label: "Integration",
        hidden: true,
        content: ({ id }) => <RunDetail id={id!} />,
      },
    ],
  },
  {
    label: "Files",
    key: "/files",
    icon: <FileOutlined />,
    content: <FileList />,
    children: [
      {
        key: "/new",
        label: "Upload file",
        hidden: true,
        content: <FileUpload />,
      },
    ],
  },
  {
    label: "Integrations",
    key: "/integrations",
    icon: <BookOutlined />,
    content: <Integrations />,
    children: [
      {
        key: "/new",
        label: "New Integration",
        hidden: true,
        content: <IntegrationCreate />,
      },
      {
        key: "/:id/edit",
        label: "Edit integration",
        hidden: true,
        content: ({ id }) => <IntegrationEdit id={id!} />,
      },
      {
        key: "/:id",
        label: "Integration",
        hidden: true,
        content: ({ id }) => <IntegrationDetail id={id!} />,
      },
    ],
  },
];

function App() {
  const { user, signOut } = useAuthenticator();
  return (
    <>
      <SyncUI />
      <AppLayout
        structure={structure}
        header={
          <Menu
            mode="horizontal"
            triggerSubMenuAction="click"
            style={{ minWidth: 400 }}
            selectedKeys={[]}
            items={[
              {
                label: user.attributes?.email,
                key: "menu",
                style: { marginLeft: "auto" },
                icon: <UserOutlined />,
                children: [
                  {
                    key: "logout",
                    label: "Logout",
                    onClick: signOut,
                  },
                ],
              },
            ]}
          ></Menu>
        }
      />
    </>
  );
}

export default withAuthenticator(App);
