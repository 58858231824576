import { gql, useMutation } from "@apollo/client";
import { DatePicker, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { EntityCreateForm } from "../components/EntityCreateForm";
import { EntitySelect } from "../components/EntitySelect";

export const RunCreate = () => {
  const navigate = useNavigate();
  const [mutate] = useMutation<{ createRun?: { id: string } }>(gql`
    mutation createRun($input: RunInput!) {
      createRun(input: $input) {
        id
      }
    }
  `);
  return (
    <EntityCreateForm
      onFinish={async (values) => {
        const res = await mutate({ variables: { input: values } });
        navigate(`/runs/${res.data?.createRun?.id}`);
      }}
    >
      <Form.Item
        name="interventionId"
        label="Intervention"
        rules={[{ required: true }]}
      >
        <EntitySelect
          entity="intervention"
          fields={[{ name: "name" }, { name: "key: id" }]}
        />
      </Form.Item>
      <Form.Item
        name="integrationId"
        label="Integration"
        rules={[{ required: true }]}
      >
        <EntitySelect
          entity="integration"
          fields={[{ name: "name" }, { name: "key: id" }]}
        />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="startDate"
        label="Start date"
        rules={[{ required: true }]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        name="programChannelName"
        label="Program channel name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="newsfeedChannelName"
        label="Newsfeed channel name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </EntityCreateForm>
  );
};
