import { gql, useMutation } from "@apollo/client";
import { Form, Input } from "antd";
import { resolvePath, useLocation, useNavigate } from "react-router-dom";
import { EntityEditForm } from "../components/EntityEditForm";
import { useRunQuery } from "./RunDetail";

interface RunEditProps {
  id: string;
}

export const RunEdit = ({ id }: RunEditProps) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { data, loading, error } = useRunQuery(id);
  const [mutate] = useMutation(gql`
    mutation updateRun($id: ID!, $input: RunUpdateInput!) {
      updateRun(id: $id, input: $input) {
        id
      }
    }
  `);
  return (
    <>
      <EntityEditForm
        data={data?.run}
        loading={loading}
        error={error}
        onFinish={async (values) => {
          await mutate({
            variables: {
              id,
              input: values,
            },
          });
          navigate(resolvePath("..", loc.pathname));
        }}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="programChannelName"
          label="Program channel name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="newsfeedChannelName"
          label="Newsfeed channel name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </EntityEditForm>
    </>
  );
};
