import { gql, useMutation } from "@apollo/client";
import { Form, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { EntityCreateForm } from "../components/EntityCreateForm";

export const IntegrationCreate = () => {
  const navigate = useNavigate();
  const [mutate] = useMutation<{ createIntegration?: { id: string } }>(gql`
    mutation createIntegration($input: IntegrationInput!) {
      createIntegration(input: $input) {
        id
      }
    }
  `);
  return (
    <EntityCreateForm
      onFinish={async (values) => {
        const res = await mutate({ variables: { input: values } });
        navigate(`/integrations/${res.data?.createIntegration?.id}`);
      }}
    >
      <Form.Item name="name" label="Name">
        <Input />
      </Form.Item>
      <Form.Item name="type" label="Type">
        <Select>
          <Select.Option key={"SLACK"}>Slack</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="config" label="Config">
        <Input.TextArea />
      </Form.Item>
    </EntityCreateForm>
  );
};
