import { gql, useMutation } from "@apollo/client";
import { Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { EntityCreateForm } from "../components/EntityCreateForm";

export const InterventionCreate = () => {
  const navigate = useNavigate();
  const [mutate] = useMutation<{ createIntervention?: { id: string } }>(gql`
    mutation createIntervention($input: InterventionInput!) {
      createIntervention(input: $input) {
        id
      }
    }
  `);
  return (
    <EntityCreateForm
      onFinish={async (values) => {
        const res = await mutate({ variables: { input: values } });
        navigate(`/interventions/${res.data?.createIntervention?.id}`);
      }}
    >
      <Form.Item name="name" label="Name">
        <Input />
      </Form.Item>
    </EntityCreateForm>
  );
};
