import { gql, useQuery } from "@apollo/client";
import { Descriptions } from "antd";
import { EntityDetail } from "../components/EntityDetail";

export const useIntegrationQuery = (id: string) => {
  return useQuery<{
    integration?: {
      id: string;
      name: string;
      type: string;
    };
  }>(
    gql`
      query getIntegration($id: ID!) {
        integration(id: $id) {
          id
          name
          type
          config
        }
      }
    `,
    { variables: { id } }
  );
};
interface IntegrationDetailProps {
  id: string;
}
export const IntegrationDetail = ({ id }: IntegrationDetailProps) => {
  const { data, error, loading } = useIntegrationQuery(id);

  return (
    <EntityDetail data={data} error={error} loading={loading}>
      <Descriptions bordered column={2}>
        <Descriptions.Item label="Name">
          {data?.integration?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          {data?.integration?.type}
        </Descriptions.Item>
      </Descriptions>
    </EntityDetail>
  );
};
