import { PlusOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Card } from "@aws-amplify/ui-react";
import { message, Upload } from "antd";
import { FileUploadRequest } from "./FileUploadRequest";

export const FileUpload = () => {
  const [uploadFn] = useMutation<{ uploadFile: string }>(
    gql`
      mutation uploadFile($key: ID!) {
        uploadFile(Key: $key)
      }
    `
  );

  return (
    <Card>
      <Upload.Dragger
        multiple={true}
        action={async (file) => {
          const { data, errors } = await uploadFn({
            variables: { key: file.name },
          });
          if (errors) {
            message.error(<>{JSON.stringify(errors)}</>);
          }
          return data!.uploadFile;
        }}
        method="PUT"
        customRequest={FileUploadRequest}
      >
        <PlusOutlined />
        <div>Upload</div>
      </Upload.Dragger>
    </Card>
  );
};
