import { gql, useMutation } from "@apollo/client";
import { Form, InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { resolvePath, useLocation, useNavigate } from "react-router-dom";
import { EntityEditForm } from "../components/EntityEditForm";
import {
  messageTypeSelect,
  useInterventionMessageQuery,
} from "./InterventionMessageDetail";

interface InterventionMessageEditProps {
  id: string;
  interventionId: string;
}
export const InterventionMessageEdit = ({
  id,
  interventionId,
}: InterventionMessageEditProps) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { data, error, loading } = useInterventionMessageQuery(
    interventionId,
    id
  );

  const [mutate] = useMutation(gql`
    mutation updateInterventionMessage(
      $interventionId: ID!
      $id: ID!
      $input: InterventionMessageInput!
    ) {
      updateInterventionMessage(
        interventionId: $interventionId
        id: $id
        input: $input
      ) {
        id
      }
    }
  `);

  return (
    <>
      <EntityEditForm
        data={data?.interventionMessage}
        error={error}
        loading={loading}
        onFinish={async (values) => {
          await mutate({
            variables: {
              interventionId,
              id,
              input: values,
            },
          });
          navigate(resolvePath("..", loc.pathname));
        }}
      >
        <Form.Item
          name="dayOffset"
          label="Day offset"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name="order" label="Order" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          {messageTypeSelect()}
        </Form.Item>
        <Form.Item name="text" label="Text">
          <TextArea rows={5} />
        </Form.Item>
      </EntityEditForm>
    </>
  );
};
