import { gql, useQuery } from "@apollo/client";
import { Descriptions, Select } from "antd";
import { EntityDetail } from "../components/EntityDetail";

export enum MessageType {
  PROGRAM = "PROGRAM",
  NEWSFEED = "NEWSFEED",
}
export const messageTypeSelect = () => {
  return (
    <Select>
      <Select.Option key={MessageType.NEWSFEED}>Newsfeed</Select.Option>
      <Select.Option key={MessageType.PROGRAM}>Program</Select.Option>
    </Select>
  );
};

export const useInterventionMessageQuery = (
  interventionId: string,
  id: string
) => {
  return useQuery<{
    interventionMessage?: {
      id: string;
      text: string;
      type: MessageType;
      fileURL: string;
      dayOffset: string;
      order: string;
    };
  }>(
    gql`
      query getInterventionMessage($interventionId: ID!, $id: ID!) {
        interventionMessage(interventionId: $interventionId, id: $id) {
          id
          text
          type
          fileURL
          dayOffset
          order
        }
      }
    `,
    { variables: { interventionId, id } }
  );
};

interface InterventionMessageDetailProps {
  id: string;
  interventionId: string;
}
export const InterventionMessageDetail = ({
  id,
  interventionId,
}: InterventionMessageDetailProps) => {
  const { data, loading, error } = useInterventionMessageQuery(
    interventionId,
    id
  );
  return (
    <>
      <EntityDetail data={data} error={error} loading={loading}>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="ID">
            {data?.interventionMessage?.id}
          </Descriptions.Item>
          <Descriptions.Item label="Text">
            {data?.interventionMessage?.text}
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            {data?.interventionMessage?.type}
          </Descriptions.Item>
          <Descriptions.Item label="DayOffset">
            {data?.interventionMessage?.dayOffset}
          </Descriptions.Item>
          <Descriptions.Item label="Order">
            {data?.interventionMessage?.order}
          </Descriptions.Item>
        </Descriptions>
      </EntityDetail>
    </>
  );
};
