import { EntityList } from "../components/EntityList";

export const Integrations = () => {
  return (
    <EntityList
      entity="integration"
      deleteArgumentsMap={{ id: "ID!" }}
      fields={[
        { title: "Name", name: "name" },
        { title: "Type", name: "type" },
      ]}
    />
  );
};
