import { DocumentNode, gql, useQuery } from "@apollo/client";
import pluralize from "pluralize";
import { upperFirst } from "lodash";
import {
  ArgumentsMap,
  getArgumentsMapAttributes,
  getArgumentsMapVariables,
} from "./common";
import { Select, SelectProps, Alert } from "antd";

interface EntitySelectField {
  name: string;
}
interface EntitySelectProps<TVariables> extends SelectProps {
  entity: string;
  fields: EntitySelectField[];

  argumentsMap?: ArgumentsMap;
  variables?: TVariables;

  queryBuilder?: (props: EntitySelectProps<TVariables>) => DocumentNode;
}

export const EntitySelect = <TVariables = any,>(
  props: EntitySelectProps<TVariables>
) => {
  const { entity, fields, argumentsMap, queryBuilder, ...rest } = props;

  const entityName = upperFirst(entity);
  const argVars = argumentsMap
    ? "(" + getArgumentsMapVariables(argumentsMap) + ")"
    : "";
  const argAttrs = argumentsMap
    ? "(" + getArgumentsMapAttributes(argumentsMap) + ")"
    : "";

  const { data, loading, error } = useQuery(
    queryBuilder
      ? queryBuilder(props)
      : gql`
      query get${entityName}${argVars} {
        result: ${pluralize(entity)}${argAttrs} {
          ${fields.map((f) => f.name).join(" ")}
        }
      }
    `,
    { fetchPolicy: "no-cache" }
  );
  if (error) {
    return <Alert type="error" message={error.message} />;
  }
  return (
    <Select loading={loading} {...rest}>
      {data &&
        data.result.map((i: any) => (
          <Select.Option key={i.key}>{i.name}</Select.Option>
        ))}
    </Select>
  );
};
