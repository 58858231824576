import type { UploadRequestOption } from "rc-upload/lib/interface";

class UploadError extends Error {
  constructor(public status: number, public url: string, message: string) {
    super(message);
  }
}

function getError(options: UploadRequestOption, xhr: XMLHttpRequest) {
  const msg = `cannot ${options.action} ${xhr.status}'`;
  const err = new UploadError(xhr.status, options.action, msg);
  return err;
}

function getBody(xhr: XMLHttpRequest) {
  const text = xhr.responseText || xhr.response;
  if (!text) {
    return text;
  }

  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
}

export function FileUploadRequest(options: UploadRequestOption) {
  const xhr = new XMLHttpRequest();

  if (options.onProgress && xhr.upload) {
    xhr.upload.onprogress = function progress(e) {
      const event: { percent: number } = { ...e, percent: 0 };
      if (e.total > 0) {
        event.percent = (e.loaded / e.total) * 100;
      }
      if (options.onProgress) {
        options.onProgress(event);
      }
    };
  }

  xhr.onerror = function error(e) {
    if (options.onError) options.onError(getError(options, xhr), getBody(xhr));
  };

  xhr.onload = function onload() {
    // allow success when 2xx status
    // see https://github.com/react-component/upload/issues/34
    if (xhr.status < 200 || xhr.status >= 300) {
      if (options.onError) {
        options.onError(getError(options, xhr), getBody(xhr));
      }
      return;
    }

    if (options.onSuccess) {
      options.onSuccess(options);
    }
  };

  xhr.open("PUT", options.action, true);

  // Has to be after `.open()`. See https://github.com/enyo/dropzone/issues/179
  if (options.withCredentials && "withCredentials" in xhr) {
    xhr.withCredentials = true;
  }

  xhr.send(options.file);

  return {
    abort() {
      xhr.abort();
    },
  };
}
