import { gql, useQuery } from "@apollo/client";
import { Col, Descriptions, Row, Button } from "antd";
import moment from "moment";
import { EntityDetail } from "../components/EntityDetail";
import { EntityList } from "../components/EntityList";
import { UploadOutlined } from "@ant-design/icons";
import { syncInviteSlackUser } from "../components/UserSlackInvitation";

export const useRunQuery = (id: string) => {
  return useQuery<{
    run?: {
      id: string;
      name: string;
      newsfeedChannelName: string;
      programChannelName: string;
      integrationId: string;
    };
  }>(
    gql`
      query getRun($id: ID!) {
        run(id: $id) {
          id
          name
          newsfeedChannelName
          programChannelName
          integrationId
        }
      }
    `,
    { variables: { id } }
  );
};

interface RunDetailProps {
  id: string;
}
export const RunDetail = ({ id }: RunDetailProps) => {
  const { data, loading, error } = useRunQuery(id);
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <EntityDetail
            data={data}
            loading={loading}
            error={error}
            extra={
              <Button
                size="small"
                icon={<UploadOutlined />}
                onClick={async () => {
                  await syncInviteSlackUser({
                    integrationId: data?.run?.integrationId!,
                  });
                }}
              />
            }
          >
            <Descriptions bordered column={2}>
              <Descriptions.Item label="ID">{data?.run?.id}</Descriptions.Item>
              <Descriptions.Item label="Name">
                {data?.run?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Program channel name">
                {data?.run?.programChannelName}
              </Descriptions.Item>
              <Descriptions.Item label="Newsfeed channel name">
                {data?.run?.newsfeedChannelName}
              </Descriptions.Item>
            </Descriptions>
          </EntityDetail>
        </Col>
        <Col span={12}>
          <EntityList
            title="Sent messages"
            entity="runMessage"
            argumentsMap={{
              runId: "ID!",
              state: "RunMessageState!",
            }}
            variables={{ runId: id, state: "SENT" }}
            fields={[
              {
                name: "date",
                title: "Date",
                render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
              },
              { name: "type", title: "Type" },
              { name: "text", title: "Text" },
            ]}
            buttons={{ delete: false }}
          />
        </Col>
        <Col span={12}>
          <EntityList
            title="Scheduled messages"
            entity="runMessage"
            argumentsMap={{ runId: "ID!", state: "RunMessageState!" }}
            variables={{ runId: id, state: "SCHEDULED" }}
            fields={[
              {
                name: "date",
                title: "Date",
                render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
              },
              { name: "type", title: "Type" },
              { name: "text", title: "Text" },
            ]}
            buttons={{ delete: false }}
          />
        </Col>
      </Row>
    </>
  );
};
