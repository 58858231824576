import { Form, Input, Modal, message } from "antd";
import { useState } from "react";
import { makeSyncUI } from "react-sync-ui";
import { syncInviteSlackUserProgress } from "./UserSlackInvitationProgress";

interface syncInviteSlackUserProps {
  integrationId: string;
}

export const syncInviteSlackUser = makeSyncUI<syncInviteSlackUserProps>(
  ({ resolve, data: { integrationId } }) => {
    const [visible, setVisible] = useState(true);
    const [form] = Form.useForm();
    return (
      <Modal
        visible={visible}
        closeIcon={<></>}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={async () => {
          form.submit();
        }}
        afterClose={() => {
          resolve();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={async (values) => {
            try {
              setVisible(false);
              await syncInviteSlackUserProgress({
                emails: values.emails.trim().split("\n"),
                channel: values.channel,
                integrationId,
              });
            } catch (err: any) {
              message.error(err.message);
            }
          }}
        >
          <Form.Item
            name="emails"
            label="Email addresses"
            rules={[{ required: true }]}
          >
            <Input.TextArea
              autoSize={{ minRows: 4, maxRows: 6 }}
              placeholder={`email1@example.com\nemail2@example.com\n...`}
            />
          </Form.Item>
          <Form.Item
            name="channel"
            label="Channel name"
            rules={[{ required: true }]}
          >
            <Input placeholder="channel name" />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
