import { EntityList } from "../components/EntityList";

export const Runs = () => {
  return (
    <EntityList
      entity="run"
      deleteArgumentsMap={{ id: "ID!" }}
      fields={[
        { title: "Name", name: "name" },
        { title: "Program channel", name: "programChannelName" },
        { title: "Newsfeed channel", name: "newsfeedChannelName" },
      ]}
      buttons={{ create: true, detail: true, delete: true }}
    />
  );
};
