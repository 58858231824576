import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { DocumentNode, gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, Popconfirm, Space, Table } from "antd";
import { Link } from "react-router-dom";
import pluralize from "pluralize";
import { upperFirst } from "lodash";
import {
  ArgumentsMap,
  getArgumentsMapAttributes,
  getArgumentsMapVariables,
} from "./common";

interface EntityListField {
  title: string;
  name: string;
  render?: (value: any, row: any) => React.ReactNode;
}
interface EntityListProps<TVariables> {
  entity: string;
  title?: string;
  fields: EntityListField[];

  variables?: TVariables;
  argumentsMap?: ArgumentsMap;
  deleteArgumentsMap?: ArgumentsMap;

  buttons?: {
    create?: boolean;
    detail?: boolean;
    edit?: boolean;
    delete?: boolean;
  };

  queryBuilder?: (props: EntityListProps<TVariables>) => DocumentNode;
  deleteBuilder?: (props: EntityListProps<TVariables>) => DocumentNode;
  // buttons?: (item: any) => React.ReactNode;
}

export const EntityList = <TVariables = any,>(
  props: EntityListProps<TVariables>
) => {
  const {
    entity,
    title,
    fields,
    variables,
    argumentsMap,
    deleteArgumentsMap,
    queryBuilder,
    deleteBuilder,
    buttons,
  } = props;

  const args = argumentsMap || null;
  const argVars = args ? "(" + getArgumentsMapVariables(args) + ")" : "";
  const argAttrs = args ? "(" + getArgumentsMapAttributes(args) + ")" : "";
  const { data, loading, refetch, error } = useQuery(
    queryBuilder
      ? queryBuilder(props)
      : gql`
          query get${upperFirst(pluralize(entity))}${argVars} {
            result: ${pluralize(entity)}${argAttrs} {
                id
                ${fields.map((f) => f.name).join(" ")}
            }
          }
        `,
    {
      fetchPolicy: "network-only",
      variables,
    }
  );

  const deleteArgVars = deleteArgumentsMap
    ? "(" + getArgumentsMapVariables(deleteArgumentsMap) + ")"
    : "";
  const deleteArgAttrs = deleteArgumentsMap
    ? "(" + getArgumentsMapAttributes(deleteArgumentsMap) + ")"
    : "";
  const [deleteItem] = useMutation(
    deleteBuilder
      ? deleteBuilder(props)
      : gql`
      mutation delete${upperFirst(entity)}${deleteArgVars} {
        delete${upperFirst(entity)}${deleteArgAttrs}{
          id
        }
      }
    `
  );
  return (
    <Card
      title={title || upperFirst(pluralize(entity))}
      bodyStyle={{ padding: 0 }}
      extra={
        (!buttons || buttons.create) && (
          <Link to="new">
            <Button size="small">
              <PlusOutlined />
            </Button>
          </Link>
        )
      }
    >
      {error && <Alert type="error" message={error?.message} />}
      <Table
        size="small"
        dataSource={data && data.result}
        loading={loading}
        rowKey="id"
      >
        {fields.map((f) => (
          <Table.Column
            title={f.title}
            key={f.name}
            dataIndex={f.name}
            render={f.render && ((value, row) => f.render!(value, row))}
            sorter={{
              compare: (a: any, b: any) => {
                if (
                  typeof a[f.name] === "string" &&
                  typeof b[f.name] === "string"
                ) {
                  return (a[f.name] as string).localeCompare(b[f.name]);
                }
                return a[f.name] - b[f.name];
              },
              multiple: 3,
            }}
          />
        ))}
        {(!buttons || buttons.delete || buttons.detail || buttons.edit) && (
          <Table.Column
            align="center"
            width={20}
            title="Actions"
            render={(_, item: { id: string }) => (
              <Space size={4}>
                {(!buttons || buttons.detail) && (
                  <Link to={item.id}>
                    <Button size="small">
                      <SearchOutlined />
                    </Button>
                  </Link>
                )}
                {(!buttons || buttons.edit) && (
                  <Link to={`${item.id}/edit`}>
                    <Button size="small">
                      <EditOutlined />
                    </Button>
                  </Link>
                )}
                {(!buttons || buttons.delete) && (
                  <Popconfirm
                    title="Delete item?"
                    placement="topRight"
                    onConfirm={async () => {
                      await deleteItem({
                        variables: { ...variables, ...item },
                      });
                      refetch();
                    }}
                  >
                    <Button size="small" danger={true}>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            )}
          />
        )}
      </Table>
    </Card>
  );
};
