import { CheckOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Button, List, Modal, Spin, Tag, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { makeSyncUI } from "react-sync-ui";

interface syncInviteSlackUserProgressProps {
  emails: string[];
  channel: string;
  integrationId: string;
}

interface SlackEmailInvite {
  state?: "running" | "success" | "error";
  error?: string;
}

const indicatorForState = (invite?: SlackEmailInvite) => {
  switch (invite?.state) {
    case "running":
      return <Spin size="small" />;
    case "success":
      return (
        <Tag color="green">
          <CheckOutlined />
        </Tag>
      );
    case "error":
      return invite.error === "already_in_channel" ? (
        <Tag color="yellow">already in channel</Tag>
      ) : (
        <Tooltip title={invite.error}>
          <Tag color="red">error</Tag>
        </Tooltip>
      );
  }
  return <>...</>;
};

export const syncInviteSlackUserProgress =
  makeSyncUI<syncInviteSlackUserProgressProps>(
    ({ resolve, data: { emails, channel, integrationId } }) => {
      const [visible, setVisible] = useState(true);
      const [isRunning, setIsRunning] = useState(false);
      const [progress, setProgress] = useState<{
        [key: string]: SlackEmailInvite;
      }>({});
      const [invite] = useMutation(gql`
        mutation InviteSlackUsers(
          $emails: [String!]!
          $channel: String!
          $integrationId: ID!
        ) {
          inviteSlackUsersToChannel(
            emails: $emails
            channel: $channel
            integrationId: $integrationId
          )
        }
      `);

      const inviteUsers = useCallback(async () => {
        setIsRunning(true);
        for (const email of emails) {
          await (async () =>
            new Promise((resolve) => setTimeout(resolve, 1000)))();
          for (const chan of channel.split(",")) {
            try {
              setProgress((p) => ({
                ...p,
                [email]: {
                  state: "running",
                },
              }));
              await invite({
                variables: {
                  emails: [email],
                  channel: chan,
                  integrationId,
                },
              });
              await (async () =>
                new Promise((resolve) => setTimeout(resolve, 3000)))();
              setProgress((p) => ({
                ...p,
                [email]: {
                  state: "success",
                },
              }));
            } catch (err: any) {
              if (err.message !== "error fetching user: users_not_found") {
                await (async () =>
                  new Promise((resolve) => setTimeout(resolve, 3000)))();
              }
              if (err.message.indexOf("slack rate limit exceeded") !== -1) {
                await (async () =>
                  new Promise((resolve) => setTimeout(resolve, 10000)))();
              }
              setProgress((p) => ({
                ...p,
                [email]: {
                  state: "error",
                  error: err.message,
                },
              }));
            }
          }
        }
        setIsRunning(false);
      }, [emails, channel, integrationId, invite]);

      useEffect(() => {
        inviteUsers();
      }, [emails, channel, integrationId, inviteUsers]);

      return (
        <Modal
          visible={visible}
          closeIcon={<></>}
          title="Inviting users to Slack"
          footer={
            <>
              please not that each user takes ~3s to invite{" "}
              <Button
                type="primary"
                disabled={isRunning}
                onClick={() => {
                  setVisible(false);
                }}
              >
                OK
              </Button>
            </>
          }
          afterClose={() => {
            resolve();
          }}
          bodyStyle={{ maxHeight: 400, overflowY: "scroll" }}
        >
          <List
            bordered
            size="small"
            dataSource={emails}
            renderItem={(item) => {
              const p = progress[item];
              return (
                <List.Item actions={[indicatorForState(p)]}>{item}</List.Item>
              );
            }}
          />
        </Modal>
      );
    }
  );
