import { gql, useMutation } from "@apollo/client";
import { Form, InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useNavigate } from "react-router-dom";
import { EntityCreateForm } from "../components/EntityCreateForm";
import { messageTypeSelect } from "./InterventionMessageDetail";

interface InterventionMessageCreateProps {
  interventionId: string;
}
export const InterventionMessageCreate = ({
  interventionId,
}: InterventionMessageCreateProps) => {
  const navigate = useNavigate();
  const [mutate] = useMutation<{
    createInterventionMessage?: { id: string };
  }>(gql`
    mutation createInterventionMessage(
      $interventionId: ID!
      $input: InterventionMessageInput!
    ) {
      createInterventionMessage(
        interventionId: $interventionId
        input: $input
      ) {
        id
      }
    }
  `);
  return (
    <EntityCreateForm
      onFinish={async (values) => {
        const res = await mutate({
          variables: { interventionId, input: values },
        });
        navigate(
          `/interventions/${interventionId}/${res.data?.createInterventionMessage?.id}`
        );
      }}
    >
      <Form.Item name="dayOffset" label="Day offset">
        <InputNumber />
      </Form.Item>
      <Form.Item name="order" label="Order">
        <InputNumber />
      </Form.Item>
      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        {messageTypeSelect()}
      </Form.Item>
      <Form.Item name="text" label="Text">
        <TextArea rows={5} />
      </Form.Item>
    </EntityCreateForm>
  );
};
