import { EntityList } from "../components/EntityList";

export const Interventions = () => {
  return (
    <EntityList
      entity="intervention"
      deleteArgumentsMap={{ id: "ID!" }}
      fields={[{ title: "Name", name: "name" }]}
    />
  );
};
