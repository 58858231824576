import { CopyOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, message, Table } from "antd";
import { EntityTable } from "../admin-components/EntityTable";

export const FileList = () => {
  const { data, loading, error, refetch } = useQuery<{
    files: { Key: string }[];
  }>(
    gql`
      query getFiles {
        files {
          Key
        }
      }
    `
  );

  const [deleteFn] = useMutation(
    gql`
      mutation deleteFile($key: ID!) {
        deleteFile(Key: $key)
      }
    `
  );

  return (
    <EntityTable
      dataSource={data?.files}
      loading={loading}
      error={error}
      rowKey="Key"
      buttonsRender={(values) => (
        <Button
          size="small"
          icon={<CopyOutlined />}
          onClick={() => {
            navigator.clipboard.writeText(
              `https://mindfulness-files.muniprojects.com/${values.Key}`
            );
            message.success("Link copied to clipboard");
          }}
        />
      )}
      onDelete={async (item) => {
        const { errors } = await deleteFn({
          variables: { key: item.Key },
        });
        if (errors) {
          throw errors;
        }
        refetch();
      }}
      buttons={{ create: true, detail: false, edit: false, delete: true }}
    >
      <Table.Column key={"Key"} dataIndex="Key" title="Name" />
    </EntityTable>
  );
};
