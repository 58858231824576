import { gql, useQuery } from "@apollo/client";
import { Col, Descriptions, Row } from "antd";
import { EntityDetail } from "../components/EntityDetail";
import { EntityList } from "../components/EntityList";

export const useInterventionQuery = (id: string) => {
  return useQuery<{
    intervention?: {
      id: string;
      name: string;
    };
  }>(
    gql`
      query getIntervention($id: ID!) {
        intervention(id: $id) {
          id
          name
        }
      }
    `,
    { variables: { id } }
  );
};

interface InterventionDetailProps {
  id: string;
}

export const InterventionDetail = ({ id }: InterventionDetailProps) => {
  const { data, error, loading } = useInterventionQuery(id);

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <EntityDetail data={data} error={error} loading={loading}>
            <Descriptions bordered column={2}>
              <Descriptions.Item label="ID">
                {data?.intervention?.id}
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                {data?.intervention?.name}
              </Descriptions.Item>
            </Descriptions>
          </EntityDetail>
        </Col>
        <Col span={24}>
          <EntityList
            entity="interventionMessage"
            variables={{ interventionId: id }}
            argumentsMap={{ interventionId: "ID!" }}
            deleteArgumentsMap={{ id: "ID!", interventionId: "ID!" }}
            fields={[
              { title: "Type", name: "type" },
              { title: "Text", name: "text" },
              { title: "Day", name: "dayOffset" },
              { title: "Order", name: "order" },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};
