import { gql, useMutation } from "@apollo/client";
import { Form, Input, Select } from "antd";
import { resolvePath, useLocation, useNavigate } from "react-router-dom";
import { EntityEditForm } from "../components/EntityEditForm";
import { useIntegrationQuery } from "./IntergrationDetail";

interface IntegrationEditProps {
  id: string;
}
export const IntegrationEdit = ({ id }: IntegrationEditProps) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { data, loading, error } = useIntegrationQuery(id);
  const [mutate] = useMutation<{ updateIntegration?: { id: string } }>(gql`
    mutation updateIntegration($id: ID!, $input: IntegrationInput!) {
      updateIntegration(id: $id, input: $input) {
        id
      }
    }
  `);
  return (
    <>
      <EntityEditForm
        data={data?.integration}
        loading={loading}
        error={error}
        onFinish={async (values) => {
          await mutate({
            variables: {
              id,
              input: values,
            },
          });
          navigate(resolvePath("..", loc.pathname));
        }}
      >
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Type">
          <Select>
            <Select.Option value={"SLACK"}>Slack</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="config" label="Config">
          <Input.TextArea />
        </Form.Item>
      </EntityEditForm>
    </>
  );
};
