import { gql, useMutation } from "@apollo/client";
import { Form, Input } from "antd";
import { resolvePath, useLocation, useNavigate } from "react-router-dom";
import { EntityEditForm } from "../components/EntityEditForm";
import { useInterventionQuery } from "./InterventionDetail";

interface InterventionEditProps {
  id: string;
}
export const InterventionEdit = ({ id }: InterventionEditProps) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { data, loading, error } = useInterventionQuery(id);
  const [mutate] = useMutation<{ updateIntervention?: { id: string } }>(gql`
    mutation updateIntervention($id: ID!, $input: InterventionInput!) {
      updateIntervention(id: $id, input: $input) {
        id
      }
    }
  `);
  return (
    <>
      <EntityEditForm
        data={data?.intervention}
        loading={loading}
        error={error}
        onFinish={async (values) => {
          await mutate({
            variables: {
              id,
              input: values,
            },
          });
          navigate(resolvePath("..", loc.pathname));
        }}
      >
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
      </EntityEditForm>
    </>
  );
};
